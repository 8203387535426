
import Vue from 'vue'
export default Vue.extend({
  name: 'ErrorBoundary',
  props: {
    stopPropagation: {
      type: Boolean,
      default: null
    },
    onError: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      errors: []
    } as { errors: object[] }
  },
  errorCaptured (err, vm, info) {
    this.errors.push({
      err,
      vm,
      info
    })

    this.$emit('errorCaptured', { err, vm, info })

    if (this.onError) { this.onError(err, vm, info) }
    if (!this.$nuxt.context.isDev && this.$isServer) {
      console.error(`Error in ${vm.type} `, err)
    }

    return this.stopPropagation !== null
      ? !this.stopPropagation
      : this.$nuxt.context.isDev
  },
  // Return type here is VNode | VNode[] but typescript rules for vue cant accept it
  render (h): any {
    const content = this.$slots.default
    return Array.isArray(content) && (content.length > 1 || !content[0].tag)
      ? h('div', {}, content)
      : content
  }
})
