<template>
  <ErrorBoundary :on-error="handleError">
    <be-dynamic
      :page="page.page"
      :content="page.content"
      :type="backendLayout"
    />
  </ErrorBoundary>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorBoundary from '../components/utilities/ErrorBoundary'
import defaultPage from '~typo3/mixins/page/default'

export default {
  components: {
    ErrorBoundary
  },
  mixins: [defaultPage],
  computed: {
    ...mapGetters(['langCode', 'dir', 'socialData', 'meta']),
    pageURL () {
      const baseURL = this.$nuxt.$typo3.domains.getDomain()?.baseURL
      const path = this.$route.fullPath === '/' ? '' : this.$route.fullPath
      return `${baseURL}${path}`
    }
  },
  methods: {
    handleError (err) {
      if (this.$sentry) {
        this.$sentry.captureException(err)
      }
    }
  },
  head () {
    const meta = []
    const link = []

    /*
    * Base meta tags
    */
    meta.push({
      hid: 'generator',
      name: 'generator',
      content: 'TYPO3 CMS x TYPO3PWA'
    })

    if (this.meta.description) {
      meta.push({
        hid: 'description',
        name: 'description',
        content: this.meta.description
      })
    }

    /*
    * Social media meta tags
    */
    if (this.socialData.ogTitle) {
      meta.push({
        property: 'og:title',
        content: this.socialData.ogTitle
      })
    }

    if (this.socialData.ogDescription) {
      meta.push({
        property: 'og:description',
        content: this.socialData.ogDescription
      })
    }

    if (this.pageURL) {
      meta.push({
        property: 'og:url',
        content: this.pageURL
      })
    }

    if (this.socialData.ogImage?.publicUrl) {
      meta.push({
        property: 'og:image',
        content: this.socialData.ogImage?.publicUrl
      })
    }

    if (this.socialData.twitterTitle) {
      meta.push({
        name: 'twitter:title',
        content: this.socialData.twitterTitle
      })
    }

    if (this.socialData.twitterDescription) {
      meta.push({
        name: 'twitter:description',
        content: this.socialData.twitterDescription
      })
    }

    if (this.socialData.twitterImage?.publicUrl) {
      meta.push({
        name: 'twitter:image',
        content: this.socialData.twitterImage.publicUrl
      })
    }

    /*
    * Robots meta
    */
    if (this.meta.robots?.noIndex) {
      meta.push({
        name: 'robots',
        content: 'noindex'
      })
    }

    if (this.meta.robots?.noFollow) {
      meta.push({
        name: 'robots',
        content: 'nofollow'
      })
    }

    /*
    * Links
    */

    if (this.pageURL) {
      link.push({
        rel: 'canonical',
        href: this.pageURL
      })
    }

    return {
      htmlAttrs: {
        lang: this.langCode || 'en',
        dir: this.dir
      },
      title: this.meta.title || 'Robomow',
      meta,
      link
    }
  }
}
</script>
